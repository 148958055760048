// This values are in testing stage. after all verified, commented types will be removed
// Note: Both FE and BE level Enum values should be same

export enum ReferenceType {

    // FacilityOwnership,
    // LicensedOrApprovedAs,
    // LevelOfCertification,
    // RUG,
    // LevelOfCare,
    State,
    OrganizationType,

    UserRole = 1,
    FacilityOwnership = 10,
    LicensedOrApprovedAs = 11,
    LevelOfCertification = 12,
    TypeOfOrganization = 13,
    Gender = 21,
    EmployeeType = 22,
    RUG = 31,
    LevelOfCare = 32,
    AllocationBasis = 33,
    AdjustmentType = 34,
    AdjustmentTypeRevenue = 35,
    AdjustmentTypeExpense = 36,
    CostCategory = 41,
    CostCategoryNursingAndResidentCare = 42,
    CostCategoryRoutineAndSupport = 43,
    CostCategoryNonAllowable = 44,
    CostCategoryAdministration = 45,
    CostCategoryHomeOffice = 46,
    CostCategoryCapital = 47,
}