import { Beneficiary, CostReportYearResponse } from '@celerkost/constants';
export interface Occupancy {
    id: number;
    dcMedicaidProviderId: string;
    facilityId: number;
    medicaidId: number;
    beneficiaryId: number;
    beneficiary: Beneficiary
    firstDateOfService: string;
    lastDateOfService: string;
    residentDays: number;
    rug: number;
    levelOfCare: number;
    noOfMealsServed: number;
    financialYear: number;
    displayFinancialYear: number;
    costReportYear: CostReportYearResponse;
    reportStartDate: string;
    reportEndDate: string;
    providerId: number;
    createdBy: number;
    updatedBy: number;
}