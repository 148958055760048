export class SearchParamsModel {
    Filters: any;
    PagingAndSortingInfo: PagingAndSortingInfo;

    constructor(
        filters: any,
        pageNumber: number = 0,
        pageSize: number = 10,
        // sorting: Sorting[] = [{
        //     SortOrder: 'ASC',
        //     ColumnName: '1'
        // }],
    ) {
        this.Filters = filters;
        this.PagingAndSortingInfo = new PagingAndSortingInfo(pageNumber, pageSize, /*sorting*/);
    }
}

export class PagingAndSortingInfo {
    Paging: Paging;
    Sorting: Sorting[];

    constructor(
        pageNo: number,
        pageSize: number,
        // sorting: Sorting[]
    ) {
        this.Paging = new Paging();
        this.Paging.PageNo = pageNo;
        this.Paging.PageSize = pageSize;
        // this.Sorting = sorting;
    }
}

export class Paging {
    PageNo!: number;
    PageSize!: number;
}

export class Sorting {
    ColumnName!: string;
    SortOrder!: string;
}