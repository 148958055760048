/* tslint:disable:max-line-length */
import { Permissions } from '@celerkost/constants';
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/home',
        permissions: [Permissions.READ],
    },
    {
        id: 'user',
        title: 'User Management',
        type: 'basic',
        icon: 'heroicons_outline:user',
        link: '/home/user',
        permissions: [Permissions.USER_VIEW],
    }
];

//#region Old Navs
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
//#endregion

export const nursingHomeNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapsable',
        icon: 'heroicons_outline:chart-pie',
        permissions: [Permissions.DASHBOARD_VIEW],
        children: [
            {
                id: 'reimbursement',
                title: 'Provider Dashboard',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/dashboard',
                permissions: [Permissions.DASHBOARD_VIEW]
            },
            {
                id: 'facility-dashboard',
                title: 'Facility Dashboard',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                permissions: [Permissions.DASHBOARD_VIEW]
            },
        ]
    },
    {
        id: 'provider',
        title: 'Provider Demographics',
        type: 'basic',
        icon: 'heroicons_solid:globe-alt',
        link: '/nursing-home/provider',
        permissions: [Permissions.PROVIDER_VIEW],
        badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
    },
    {
        id: 'facility',
        title: 'Facility Management',
        type: 'basic',
        icon: 'heroicons_outline:library',
        link: '/nursing-home/facility',
        permissions: [Permissions.FACILITY_VIEW],
        badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
    },
    {
        id: 'employee',
        title: 'Employee Management',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/nursing-home/employee',
        permissions: [Permissions.EMPLOYEE_VIEW],
        badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
    },
    {
        id: 'beneficiary',
        title: 'Beneficiary Management',
        type: 'basic',
        icon: 'heroicons_outline:support',
        link: '/nursing-home/beneficiary',
        permissions: [Permissions.BENEFICIARY_VIEW],
        badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
    },
    {
        id: 'occupancy',
        title: 'Occupancy Management',
        type: 'basic',
        icon: 'heroicons_outline:map',
        link: '/nursing-home/occupancy',
        permissions: [Permissions.OCCUPANCY_VIEW],
        badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
    },
    {
        id: 'certificate-authority',
        title: 'Certification Authority',
        type: 'basic',
        icon: 'heroicons_outline:shield-check',
        link: '/nursing-home/certificate-authority',
        permissions: [Permissions.CERTIFICATION_VIEW_ALL],
        badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
    },
    {
        id: 'reimbursement',
        title: 'Reimbursement',
        type: 'collapsable',
        icon: 'heroicons_outline:cash',
        permissions: [Permissions.REIMBURSEMENT_VIEW, Permissions.CASH_RECEIPTS_VIEW],
        children: [
            {
                id: 'reimbursements',
                title: 'Reimbursements',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/reimbursement/list',
                permissions: [Permissions.REIMBURSEMENT_VIEW],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
            },
            {
                id: 'cash-receipts',
                title: 'Cash Receipts',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/reimbursement/cash-receipts',
                permissions: [Permissions.CASH_RECEIPTS_VIEW],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
            },
        ]
    },
    {
        id: 'expense-entry',
        title: 'Expense Entry',
        type: 'collapsable',
        icon: 'heroicons_outline:currency-dollar',
        permissions: [Permissions.READ],
        children: [
            {
                id: 'nursing-and-resident-care',
                title: 'Nursing & Res. Care',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/expense-entry/nursing-and-resident-care',
                permissions: [Permissions.EXPENSE_ENTRY_NURSING_RESIDENT_CARE_VIEW_ALL],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
            },
            {
                id: 'routine-and-support',
                title: 'Routine & Support',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/expense-entry/routine-and-support',
                permissions: [Permissions.EXPENSE_ENTRY_ROUTINE_AND_SUPPORT_VIEW_ALL],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
            },
            {
                id: 'non-allowable',
                title: 'Non Allowable',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/expense-entry/non-allowable',
                permissions: [Permissions.EXPENSE_ENTRY_NON_ALLOWABLE_VIEW_ALL],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
            },
            {
                id: 'administration',
                title: 'Administration',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/expense-entry/administration',
                permissions: [Permissions.EXPENSE_ENTRY_ADMINISTRATION_VIEW_ALL],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
            },
            {
                id: 'home-office',
                title: 'Home Office',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/expense-entry/home-office',
                permissions: [Permissions.EXPENSE_ENTRY_HOME_OFFICE_VIEW_ALL],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
            },
            {
                id: 'capital',
                title: 'Capital',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/expense-entry/capital',
                permissions: [Permissions.EXPENSE_ENTRY_CAPITAL_VIEW_ALL],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
            },
            {
                id: 'adjustments',
                title: 'Adjustments',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/expense-entry/expense-and-revenue-adjustment',
                permissions: [Permissions.EXPENSE_AND_REVENUE_ADJUSTMENT_VIEW_ALL],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
            },
        ]
    },
    {
        id: 'depreciation-schedule',
        title: 'Depreciation Schedule',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/nursing-home/depreciation-schedule',
        permissions: [Permissions.READ, Permissions.DEPRECIATION_SCHEDULE_VIEW],
        badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
    },
    {
        id: 'allocations',
        title: 'Allocations',
        type: 'basic',
        icon: 'heroicons_solid:chip',
        link: '/nursing-home/allocations',
        permissions: [Permissions.READ, Permissions.ALLOCATION_VIEW],
        badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
    },
    {
        id: 'costReport',
        title: 'Cost Report',
        type: 'collapsable',
        icon: 'heroicons_outline:document-report',
        permissions: [Permissions.READ, Permissions.COST_REPORT_VIEW],
        children: [
            {
                id: 'active-cost Reports',
                title: 'Active Cost Reports',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/costReportView',
                permissions: [Permissions.COST_REPORT_VIEW],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' },
            },
            {
                id: 'archived-cost-reports',
                title: 'Archived Cost Reports',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/costReportView/archivedCostReport',
                permissions: [Permissions.COST_REPORT_VIEW]
            },
        ]
    },
    {
        id: 'import-data',
        title: 'Import Data',
        type: 'basic',
        icon: 'heroicons_outline:document-download',
        // link: '/nursing-home/dashboard',
        permissions: [Permissions.READ],
    },
    {
        id: 'archived-data',
        title: 'Archived Data',
        type: 'collapsable',
        icon: 'heroicons_outline:archive',
        permissions: [Permissions.READ],
        children: [
            {
                id: 'provider-archived-data',
                title: 'Provider Archived Data',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/archivedData/provider-archived-data',
                permissions: [Permissions.READ]
            },
            {
                id: 'facility-archived-d    ata',
                title: 'Facility Archived Data',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/archivedData/facility-archived-data',
                permissions: [Permissions.READ]
            }
        ]
    },
    {
        id: 'financial-year-closure',
        title: 'Financial Year Closure',
        type: 'basic',
        icon: 'heroicons_outline:receipt-tax',
        // link: '/nursing-home/dashboard',
        permissions: [Permissions.READ],
    },
    {
        id: 'expenditure-analysis',
        title: 'Expenditure Analysis',
        type: 'basic',
        icon: 'heroicons_outline:chart-square-bar',
        // link: '/nursing-home/dashboard',
        permissions: [Permissions.READ],
    },
    {
        id: 'configurations',
        title: 'Configurations',
        type: 'collapsable',
        icon: 'heroicons_outline:cog',
        permissions: [Permissions.READ, Permissions.CONFIGURATION_VIEW],
        children: [
            {
                id: 'nursing-homes-rates',
                title: 'Nursing Home Rates',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/shared/nursing-homes-rates',
                permissions: [Permissions.NURSING_HOME_RATES_GETALL],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }
            }, {
                id: 'allocation-rates',
                title: 'Allocation Rates',
                type: 'basic',
                icon: 'heroicons_solid:chevron-right',
                link: '/nursing-home/shared/allocation-rates',
                permissions: [Permissions.Allocation_RATES_GETALL],
                badge: { classes: 'w-3 h-3 bg-green-400 rounded-full' }                
            }
        ]
    },
];
