import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, QueryResult } from '@celerkost/constants';
import { SearchParamsModel } from '@celerkost/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedUiService {

  constructor(private httpClient: HttpClient) { }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}NursingHomeRates/getAll`, searchQuery);
  }
  GetAllRugValues(facilityId: any, financialYearId: any): Observable<QueryResult> {
    let params: HttpParams = new HttpParams().set('financialYearId', financialYearId);

    if (facilityId != null) {
      params = params.set('facilityId', facilityId)
    }

    return this.httpClient.get<QueryResult>(`${environment.baseApiURL}NursingHomeRates/GetAllDistinctRugValues`, { params: params });
  }

  getById(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams()
      .set('id', id);
    return this.httpClient.get<QueryResult>(`${environment.baseApiURL}employee/getbyId`, { params: params });
  }
}
