import { ComponentRef, OnInit } from '@angular/core';
import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SkeltonLoaderComponent } from './skelton-loader.component';
import { SkeletonType } from './skelton-types';

@Directive({
  selector: '[codiceSkeletonLoader]'
})
export class SkeletonLoaderDirective implements OnInit {

  render: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  private _isLoading: boolean;
  private _skeletonType: SkeletonType;
  private _height: string;
  private _width: string;
  private _margin: string;
  private _borderRadius: string;
  private _tableRows: number;
  private _tableCols: number;
  private _listRows: number;

  @Input() set codiceSkeletonLoader(isLoading: boolean) {
    this._isLoading = isLoading;
    this.render.next();
  }

  @Input() set codiceSkeletonLoaderType(skeletonType: SkeletonType) {
    this._skeletonType = skeletonType;
    this.render.next();
  }
  @Input() set codiceSkeletonLoaderHeight(height: string) {
    this._height = height;
    this.render.next();
  }
  @Input() set codiceSkeletonLoaderWidth(width: string) {
    this._width = width;
    this.render.next();
  }

  @Input() set codiceSkeletonLoaderMargin(margin: string) {
    this._margin = margin;
    this.render.next();
  }

  @Input() set codiceSkeletonLoaderBorderRadius(borderRadius: string) {
    this._borderRadius = borderRadius;
    this.render.next();
  }

  @Input() set codiceSkeletonLoaderTableRows(tableRows: number) {
    this._tableRows = tableRows;
    this.render.next();
  }

  @Input() set codiceSkeletonLoaderTableCols(tableCols: number) {
    this._tableCols = tableCols;
    this.render.next();
  }

  @Input() set codiceSkeletonLoaderListRows(listRows: number) {
    this._listRows = listRows;
    this.render.next();
  }

  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.render.subscribe(this.renderSkeleton.bind(this))
  }

  renderSkeleton(): void {
    this.viewContainer.clear();
    if (this._isLoading) {
      const componentRef: ComponentRef<SkeltonLoaderComponent> = this.viewContainer.createComponent(SkeltonLoaderComponent);
      componentRef.instance.type = this._skeletonType;
      componentRef.instance.height = this._height;
      componentRef.instance.width = this._width;
      componentRef.instance.margin = this._margin;
      componentRef.instance.borderRadius = this._borderRadius;
      componentRef.instance.tableRows = this._tableRows;
      componentRef.instance.tableColumns = this._tableCols;
      componentRef.instance.listRows = this._listRows;
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
