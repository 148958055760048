import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'codice-action-notification',
  templateUrl: './action-notification.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ActionNotificationComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit(): void {
  }

  public onDismiss(): void {
		this.data.snackBar.dismiss();
	}

}
