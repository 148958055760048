import { FacilityService } from 'app/services/api/facility.service';
import { AppConfigService, AuthDataService } from '@celerkost/services';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { environment } from 'environments/environment';
import { CostReportYearService } from 'app/services/api/cost-report-year.service';
import { CostReportYearResponse, FacilityResponse, QueryResult } from '@celerkost/constants';
import { Subscribable } from '@celerkost/models';

@Component({
  selector: 'nursing-home-layout',
  templateUrl: './nursing-home.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NursingHomeLayoutComponent extends Subscribable implements OnInit, OnDestroy {

  isScreenSmall: boolean;
  isNavToggleHide: boolean = false;
  navigation: Navigation;
  private unsubscribeAll: Subject<any> = new Subject<any>();
  version = environment.version;
  costReportYearList: CostReportYearResponse[] = [];
  providerFacilityList: FacilityResponse[] = [];
  selectedCostReportYear: CostReportYearResponse = null;
  selectedFacilityId: number = 0;
  hideFinancialYearList: boolean;
  hideFacilityList: boolean;
  envTag = environment.tag;

  get currentYear(): number {
    return new Date().getFullYear();
  }

  constructor(
    private navigationService: NavigationService,
    private fuseMediaWatcherService: FuseMediaWatcherService,
    private fuseNavigationService: FuseNavigationService,
    private costReportYearService: CostReportYearService,
    private authDataService: AuthDataService,
    public appConfigService: AppConfigService,
    private facilityService: FacilityService,
  ) { super(); }

  ngOnInit(): void {

    this.loadCostReportYears(this.authDataService.providerId.toString());
    this.loadAllFacilitiesByProvider(this.authDataService.providerId);

    // Subscribe to navigation data
    this.navigationService.navigation$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this.fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this.appConfigService.costReportYear
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((costReportYear) => {
        this.selectedCostReportYear = costReportYear;
      });

    this.appConfigService.facilityListUpdated
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        this.loadAllFacilitiesByProvider(this.authDataService.providerId);
      });

    this.appConfigService.facility
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((facilityId) => {
        this.selectedFacilityId = facilityId ?? 0;
        this.appConfigService.facilityId = facilityId ?? 0;
      });

    this.subscription = this.appConfigService.hideFacilityList.subscribe((res: boolean) => {
      this.hideFacilityList = res;
    });

    this.subscription = this.appConfigService.hideFinancialYearList.subscribe((res: boolean) => {
      this.hideFinancialYearList = res;
    });

  }

  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this.fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  loadCostReportYears(providerId: string): void {
    this.subscription = this.costReportYearService.getAllByProviderId(providerId).subscribe((res: QueryResult) => {
      if (res.success) {
        this.costReportYearList = res.response;
        this.costReportYearList[this.costReportYearList.length - 1] ? this.authDataService.costReportYear = this.costReportYearList[this.costReportYearList.length - 1] : null;
        this.selectedCostReportYear = this.costReportYearList[this.costReportYearList.length - 1];
        this.appConfigService.costReportYear.next(this.selectedCostReportYear);
        this.appConfigService.financialYearId = this.selectedCostReportYear.id
      }
    });
  }

  loadAllFacilitiesByProvider(providerId: number): void {
    this.appConfigService.facilityId = null;
    this.subscription = this.facilityService.getAllByProviderId(providerId).subscribe((res: QueryResult) => {
      if (res.success) {
        this.providerFacilityList = res.response;
        this.providerFacilityList.unshift({ id: 0, facilityName: 'All Facilities' });
      }
    });
  }
  
  onReportingYearChanged(costReportYearId: number): void {
    const costReportYear: CostReportYearResponse = this.costReportYearList.find(x => x.id == costReportYearId);
    this.authDataService.costReportYear = costReportYear;
    this.appConfigService.financialYearId = costReportYear.id
    this.appConfigService.costReportYear.next(costReportYear);
  }

  onFacilityChanged(facilityId: number): void {
    this.appConfigService.facilityId = facilityId;
    this.appConfigService.facility.next(facilityId > 0 ? facilityId : null);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
    this.unSubscribe();
  }

}
