import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, QueryResult } from '@celerkost/constants';
import { SearchParamsModel } from '@celerkost/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AuthDataService } from '../data/auth-data.service';

@Injectable({
  providedIn: 'root'
})
export class DepreciationScheduleService {

  constructor(private httpClient: HttpClient,private authDataService: AuthDataService) { }

  add(formData: FormData): Observable<QueryResult> {
   
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}DepreciationRecord/Add`, formData);
  }
  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}DepreciationRecord/getAll`, searchQuery);
  }
  getById(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams()
      .set('id', id);
    return this.httpClient.get<QueryResult>(`${environment.baseApiURL}DepreciationRecord/getbyId`, { params: params });
  }

  update(formData: FormData): Observable<QueryResult> {
   
    return this.httpClient.put<QueryResult>(`${environment.baseApiURL}DepreciationRecord/update`, formData);
  }
  delete(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams().set('id', id).set('userId', this.authDataService.userId);
    return this.httpClient.delete<QueryResult>(`${environment.baseApiURL}DepreciationRecord/delete`, { params: params });
  }
}
