import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { MessageType, NavToolbarButtonTemplateType, NotificationService, ToolbarService } from '@celerkost/libs';

@Injectable()
export class ErrorHandleInterceptor implements HttpInterceptor {

  constructor(
    private notification: NotificationService,
    private toolbarService: ToolbarService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let newReq = request.clone();
    if (!window.navigator.onLine) {
      this.notification.show('Please check your internet connection.', MessageType.Info);
    } else {
      return next.handle(newReq).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if ([401, 403, 500].includes(error.status)) {
              this.notification.show('Server side error occurred. Try again...', MessageType.Error);
            } else {
              this.notification.show('Sorry! Error occurred. Please contact System Administrator.', MessageType.Error);
            }
            this.toolbarService.removeLoadingButtonsByTemplates([NavToolbarButtonTemplateType.Add]);
          }
          return throwError(() => new Error(error))
        })
      );
    }
  }

}
