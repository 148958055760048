import { Facility } from "./facility";

export interface Beneficiary {
    id: number;
    medicaidId: string;
    confirmMedicaidId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: string;
    addressOne: string;
    addressTwo: string;
    city: string;
    state: string;
    zip: string;
    telephone: string;
    email: string;
    ssn: string;
    facilityId: number;
    facility: Facility;
    eligibilityStartDate: string;
    eligibilityEndDate: string;
    reportingYear: string;
    createdBy: string;
    createdDate: string;
}