import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeltonLoaderComponent } from './skelton-loader.component';
import { SkeletonLoaderDirective } from './skelton-loader.directive';

@NgModule({
  declarations: [
    SkeltonLoaderComponent,
    SkeletonLoaderDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SkeltonLoaderComponent,
    SkeletonLoaderDirective
  ]
})
export class CodiceSkeltonLoaderModule { }
