import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, Certification, EntityQueryResult, QueryResult } from '@celerkost/constants';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { SearchParamsModel } from '@celerkost/models';
@Injectable({
  providedIn: 'root'
})
export class CertificateAuthorityService {

  constructor(private httpClient: HttpClient) { }

  add(formData: FormData): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}certification/add`, formData);
  }

  update(formData: FormData): Observable<QueryResult> {
    return this.httpClient.put<QueryResult>(`${environment.baseApiURL}certification/update`, formData);
  }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}certification/getAll`, searchQuery);
  }

  getById(id: number): Observable<EntityQueryResult<Certification>> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpClient.get<EntityQueryResult<Certification>>(`${environment.baseApiURL}certification/getById`, { params: params });
  }

  getActiveCertification(financialYear: number, facilityId: number): Observable<EntityQueryResult<Certification>> {
    const params: HttpParams = new HttpParams().set('financialYear', financialYear).set('facilityId', facilityId);
    return this.httpClient.get<EntityQueryResult<Certification>>(`${environment.baseApiURL}certification/getActiveCertification`, { params: params });
  }

}
