import { Component, OnInit } from '@angular/core';
import { SkeletonType } from './skelton-types';

@Component({
  selector: 'codice-skeleton-loader',
  templateUrl: './skelton-loader.component.html',
  styleUrls: ['./skelton-loader.component.scss']
})
export class SkeltonLoaderComponent implements OnInit {

  type: SkeletonType;
  width: string = '';
  height: string = '';
  margin: string = '0';
  borderRadius: string = '';
  isTable: boolean = false;
  isList: boolean = false;
  isAvatar: boolean = false;
  tableRows: number = 0;
  tableColumns: number = 0;
  rowsArray: any = [];
  colsArray: any = [];
  tHeadArray: any = []
  listRows: number = 1;
  listRowsArray: any = [];
  gridColumns: string = '';
  constructor() { }

  ngOnInit(): void {
    this.handleSkeletonTypes();
  }

  get customStyles(): { [key: string]: any } {
    return {
      width: this.width ? this.width : '',
      height: this.height ? this.height : '',
      maxWidth: '100%',
      margin: this.margin,
      'border-radius': this.borderRadius
    };
  }

  get headerStyles(): { [key: string]: any } {
    return {
      display: 'grid',
      width: '95%',
      margin: '0 auto 0 auto',
      'grid-template-columns': this.gridColumns
    };
  }

  handleSkeletonTypes(): void {
    switch (this.type) {
      case 'title':
        this.height = '40px';
        this.width = '350px';
        break;
      case 'subtitle':
        this.height = '30px';
        this.width = '340px';
        break;
      case 'description':
        this.height = '50px';
        this.width = '100%';
        break;
      case 'button':
        this.height = '20px';
        this.width = '50px';
        break;
      case 'table':
        this.rowsArray = Array(this.tableRows).fill(0).map((x, i) => i);
        this.colsArray = Array(this.tableColumns).fill(0).map((x, i) => i);
        if (this.tableColumns < 6) {
          this.tHeadArray = Array(5).fill(0).map((x, i) => i);
          this.gridColumns = '1fr 1fr 1fr 1fr 1fr';
        } else {
          this.tHeadArray = Array(10).fill(0).map((x, i) => i);
          this.gridColumns = '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr';
        }
        this.isTable = true;
        break;
      case 'list':
        this.listRowsArray = Array(this.listRows).fill(0).map((x, i) => i);
        this.isList = true;
        break;
      case 'avatar':
        this.isAvatar = true;
        break;
      default:
        break;
    }
  }

}
