<div class="mat-snackbar content-between">
  <div class="mat-snackbar__message">
    <mat-icon class="snackbar-icon" svgIcon="{{data.icon}}"></mat-icon>
    <span class="pl-4">{{data.message}}</span>
  </div>
  <div class="mat-snackbar__close pl-2" *ngIf="data.showCloseButton">
    <button mat-icon-button (click)="onDismiss()">
      <mat-icon svgIcon="feather:x"></mat-icon>
    </button>
  </div>
</div>