import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CostReportYearResponse } from '@celerkost/constants';
import { Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthDataService } from '@celerkost/services';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  costReportYear: Subject<CostReportYearResponse> = new Subject<CostReportYearResponse>();
  facility: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  facilityListUpdated: Subject<any> = new Subject<any>();
  hideFinancialYearList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hideFacilityList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private bnIdle: BnNgIdleService,
    private confirmationService: FuseConfirmationService,
    private router: Router,
    private authDataService: AuthDataService
  ) { }

  get facilityId(): any {
    return Number(localStorage.getItem('_selIFsl_')) > 0 ? Number(localStorage.getItem('_selIFsl_')) : null;
  }

  set facilityId(id: any) {
    localStorage.setItem('_selIFsl_', id);
  }

  get financialYearId(): any {
    return Number(localStorage.getItem('_selIYFin_')) > 0 ? Number(localStorage.getItem('_selIYFin_')) : null;
  }

  set financialYearId(id: any) {
    localStorage.setItem('_selIYFin_', id);
  }

  initSessionTimeoutIdle(): void {

    // start watching 20 minuit's
    this.bnIdle.startWatching(1200).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        const confirmation = this.confirmationService.open({
          title: 'Session Expiring...',
          message: 'Your session has expired. You have been logged out.',
          icon: {
            show: true,
            name: 'mat_solid:timer',
            color: 'accent'
          },
          actions: {
            confirm: {
              label: 'Login Again',
              color: 'accent'
            },
            cancel: {
              show: false
            }
          }
        });

        this.authDataService.signOut();

        confirmation.afterClosed().subscribe((result) => {
          if (result) {
            this.router.navigate(['auth/sign-in']);
          }
        });
        this.bnIdle.stopTimer();
      }
    });
  }

  formatPhoneNumber(phoneNumber: string): string {
    const phoneNumberPattern = /(\d{3})(\d{3})(\d{4})/;
    return phoneNumber.replace(phoneNumberPattern, '$1 $2 $3');
  }

}