import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryResult } from '@celerkost/constants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpClient: HttpClient) { }
  GetRoles(): Observable<QueryResult> {
    return this.httpClient.get<QueryResult>(`${environment.baseApiURL}Role/getAll`);
  }
}
