import { NavToolbarButtonType } from './../constants/nav-toolbar-button.type';
import { ToolbarButtonTemplates } from './../models/toolbar-button.templates';
import { NavToolbarButtonTemplateType } from './../constants/nav-toolbar-button-template.type';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NavToolbarButton } from '../models/nav-toolbar';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  visibleButtons: BehaviorSubject<NavToolbarButton[]> = new BehaviorSubject<NavToolbarButton[]>([]);
  expandedButtons: BehaviorSubject<NavToolbarButton[]> = new BehaviorSubject<NavToolbarButton[]>([]);
  loadingButtons: BehaviorSubject<NavToolbarButton[]> = new BehaviorSubject<NavToolbarButton[]>([]);
  disabledButtons: BehaviorSubject<NavToolbarButton[]> = new BehaviorSubject<NavToolbarButton[]>([]);
  private changes: Subject<NavToolbarButtonType> = new Subject<NavToolbarButtonType>();

  setVisibleButtons(buttons: NavToolbarButton[]): void {
    this.visibleButtons.next(buttons);
  }
  setExpandedButtons(buttons: NavToolbarButton[]): void {
    this.expandedButtons.next(buttons);
  }
  setLoadingButtons(buttons: NavToolbarButton[]): void {
    this.loadingButtons.next(buttons);
  }
  addLoadingButtons(buttons: NavToolbarButton[]): void {
    this.loadingButtons.next([...this.loadingButtons.value, ...buttons]);
  }
  getExpandedButtonTypes(): NavToolbarButtonType[] {
    return this.expandedButtons.value.map(e => e.ButtonType);
  }
  getVisibleButtonTypes(): NavToolbarButtonType[] {
    return this.visibleButtons.value.map(e => e.ButtonType);
  }
  removeLoadingButtons(buttons: NavToolbarButton[]): void {
    this.loadingButtons.next(this.loadingButtons.value.filter(btn => { buttons.indexOf(btn) < 0 }));
  }

  addDisabledButtons(buttons: NavToolbarButton[]): void {
    this.disabledButtons.next(buttons);
  }

  removeExpandedButtons(buttons: NavToolbarButton[]): void {
    this.expandedButtons.next(this.expandedButtons.value.filter(btn => buttons.indexOf(btn) < 0 ));
  }

  addExpandedButtons(buttons: NavToolbarButton[]): void {
    this.expandedButtons.next([...this.expandedButtons.value, ...buttons]);
  }

  setCustomButtons(buttons: NavToolbarButton[]): void {
    this.visibleButtons.next([...this.visibleButtons.value, ...buttons]);
  }

  setVisibleButtonsByTemplates(buttonTemplates: NavToolbarButtonTemplateType[]): void {
    this.setVisibleButtons(buttonTemplates.map((template: NavToolbarButtonTemplateType) => ToolbarButtonTemplates.getButtonByTemplate(template)));
  }
  setExpandedButtonsByTemplates(buttonTemplates: NavToolbarButtonTemplateType[]): void {
    this.setExpandedButtons(buttonTemplates.map((template: NavToolbarButtonTemplateType) => ToolbarButtonTemplates.getButtonByTemplate(template)));
  }
  setLoadingButtonsByTemplates(buttonTemplates: NavToolbarButtonTemplateType[]): void {
    this.setLoadingButtons(buttonTemplates.map((template: NavToolbarButtonTemplateType) => ToolbarButtonTemplates.getButtonByTemplate(template)));
  }
  addLoadingButtonsByTemplates(buttonTemplates: NavToolbarButtonTemplateType[]): void {
    this.addLoadingButtons(buttonTemplates.map((template: NavToolbarButtonTemplateType) => ToolbarButtonTemplates.getButtonByTemplate(template)));
  }
  removeLoadingButtonsByTemplates(buttonTemplates: NavToolbarButtonTemplateType[]): void {
    this.removeLoadingButtons(buttonTemplates.map((template: NavToolbarButtonTemplateType) => ToolbarButtonTemplates.getButtonByTemplate(template)));
  }
  addExpandedButtonsByTemplates(buttonTemplates: NavToolbarButtonTemplateType[]): void {
    this.addExpandedButtons(buttonTemplates.map((template: NavToolbarButtonTemplateType) => ToolbarButtonTemplates.getButtonByTemplate(template)));
  }
  removeExpandedButtonsByTemplates(buttonTemplates: NavToolbarButtonTemplateType[]): void {
    this.removeExpandedButtons(buttonTemplates.map((template: NavToolbarButtonTemplateType) => ToolbarButtonTemplates.getButtonByTemplate(template)));
  }
  addDisabledButtonsByTemplates(buttonTemplates: NavToolbarButtonTemplateType[]): void {
    this.addDisabledButtons(buttonTemplates.map((template: NavToolbarButtonTemplateType) => ToolbarButtonTemplates.getButtonByTemplate(template)));
  }

  onValueChange(type: NavToolbarButtonType): void {
    this.changes.next(type);
  }

  valueChanges(): Observable<NavToolbarButtonType> {
    return this.changes.asObservable();
  }
  reset(): void {
    this.visibleButtons.next([]);
    this.expandedButtons.next([]);
  }
}
