export enum Permissions {

    READ = 'Read', // Default - for testing

    DASHBOARD_VIEW = 'Dashboard_View',

    USER_VIEW = 'Users_View',
    USER_REGISTER_NEW = 'Users_RegisterNew',
    USER_UPDATE = 'Users_Update',
    USER_DELETE = 'Users_Delete',

    PROVIDER_UPDATE = 'Provider_Update',
    PROVIDER_VIEW = 'Provider_View',
    PROVIDER_VIEW_ALL = 'Provider_ViewAll',

    FACILITY_ADD = 'Facility_Add',
    FACILITY_UPDATE = 'Facility_Update',
    FACILITY_DELETE = 'Facility_Delete',
    FACILITY_VIEW = 'Facility_View',
    FACILITY_VIEW_ALL = 'Facility_ViewAll',

    EMPLOYEE_ADD = 'Employee_Add',
    EMPLOYEE_UPDATE = 'Employee_Update',
    EMPLOYEE_DELETE = 'Employee_Delete',
    EMPLOYEE_VIEW = 'Employee_View',
    EMPLOYEE_VIEW_ALL = 'Employee_ViewAll',

    BENEFICIARY_ADD = 'Beneficiary_Add',
    BENEFICIARY_UPDATE = 'Beneficiary_Update',
    BENEFICIARY_DELETE = 'Beneficiary_Delete',
    BENEFICIARY_VIEW = 'Beneficiary_View',
    BENEFICIARY_VIEW_ALL = 'Beneficiary_ViewAll',

    OCCUPANCY_ADD = 'Occupancy_Add',
    OCCUPANCY_UPDATE = 'Occupancy_Update',
    OCCUPANCY_DELETE = 'Occupancy_Delete',
    OCCUPANCY_VIEW = 'Occupancy_View',
    OCCUPANCY_VIEW_ALL = 'Occupancy_ViewAll',

    REIMBURSEMENT_ADD = 'Reimbursement_Add',
    REIMBURSEMENT_UPDATE = 'Reimbursement_Update',
    REIMBURSEMENT_DELETE = 'Reimbursement_Delete',
    REIMBURSEMENT_VIEW = 'Reimbursement_View',
    REIMBURSEMENT_VIEW_ALL = 'Reimbursement_ViewAll',

    CASH_RECEIPTS_VIEW = 'CashReceipts_View',

    EXPENSE_ENTRY_VIEW = 'ExpenseEntry_View',

    DEPRECIATION_SCHEDULE_VIEW = 'Depreciation_View',

    COST_REPORT_VIEW = 'CostReport_View',

    ALLOCATION_VIEW = 'Allocation_View',

    CONFIGURATION_VIEW = 'Configuration_View',

    COST_REPORT_YEAR_VIEW = 'CostReportYear_View',

    CERTIFICATION_ADD = 'Certification_Add',
    CERTIFICATION_UPDATE = 'Certification_Update',
    CERTIFICATION_VIEW = 'Certification_View',
    CERTIFICATION_VIEW_ALL = 'Certification_ViewAll',

    EXPENSE_ENTRY_ROUTINE_AND_SUPPORT_ADD = 'ExpenseEntryRoutineAndSupport_Add',
    EXPENSE_ENTRY_ROUTINE_AND_SUPPORT_UPDATE = 'ExpenseEntryRoutineAndSupport_Update',
    EXPENSE_ENTRY_ROUTINE_AND_SUPPORT_DELETE = 'ExpenseEntryRoutineAndSupport_Delete',
    EXPENSE_ENTRY_ROUTINE_AND_SUPPORT_VIEW = 'ExpenseEntryRoutineAndSupport_View',
    EXPENSE_ENTRY_ROUTINE_AND_SUPPORT_VIEW_ALL = 'ExpenseEntryRoutineAndSupport_ViewAll',

    EXPENSE_ENTRY_NON_ALLOWABLE_ADD = "ExpenseEntryNonAllowable_Add",
    EXPENSE_ENTRY_NON_ALLOWABLE_UPDATE = "ExpenseEntryNonAllowable_Update",
    EXPENSE_ENTRY_NON_ALLOWABLE_DELETE = "ExpenseEntryNonAllowable_Delete",
    EXPENSE_ENTRY_NON_ALLOWABLE_VIEW = "ExpenseEntryNonAllowable_View",
    EXPENSE_ENTRY_NON_ALLOWABLE_VIEW_ALL = "ExpenseEntryNonAllowable_ViewAll",

    EXPENSE_ENTRY_NURSING_RESIDENT_CARE_ADD = "ExpenseEntryNursingAndResidentCare_Add",
    EXPENSE_ENTRY_NURSING_RESIDENT_CARE_UPDATE = "ExpenseEntryNursingAndResidentCare_Update",
    EXPENSE_ENTRY_NURSING_RESIDENT_CARE_DELETE = "ExpenseEntryNursingAndResidentCare_Delete",
    EXPENSE_ENTRY_NURSING_RESIDENT_CARE_VIEW = "ExpenseEntryNursingAndResidentCare_View",
    EXPENSE_ENTRY_NURSING_RESIDENT_CARE_VIEW_ALL = "ExpenseEntryNursingAndResidentCare_ViewAll",

    NURSING_HOME_RATES_GETALL = "NursingHomeRates_GetAll",
    Allocation_RATES_GETALL = "Allocation_RATES_GETALL",

    EXPENSE_ENTRY_ADMINISTRATION_ADD = "ExpenseEntryAdministration_Add",
    EXPENSE_ENTRY_ADMINISTRATION_UPDATE = "ExpenseEntryAdministration_Update",
    EXPENSE_ENTRY_ADMINISTRATION_DELETE = "ExpenseEntryAdministration_Delete",
    EXPENSE_ENTRY_ADMINISTRATION_VIEW = "ExpenseEntryAdministration_View",
    EXPENSE_ENTRY_ADMINISTRATION_VIEW_ALL = "ExpenseEntryAdministration_ViewAll",

    EXPENSE_ENTRY_HOME_OFFICE_ADD = "ExpenseEntryHomeOffice_Add",
    EXPENSE_ENTRY_HOME_OFFICE_UPDATE = "ExpenseEntryHomeOffice_Update",
    EXPENSE_ENTRY_HOME_OFFICE_DELETE = "ExpenseEntryHomeOffice_Delete",
    EXPENSE_ENTRY_HOME_OFFICE_VIEW = "ExpenseEntryHomeOffice_View",
    EXPENSE_ENTRY_HOME_OFFICE_VIEW_ALL = "ExpenseEntryHomeOffice_ViewAll",

    EXPENSE_ENTRY_CAPITAL_ADD = "ExpenseEntryCapital_Add",
    EXPENSE_ENTRY_CAPITAL_UPDATE = "ExpenseEntryCapital_Update",
    EXPENSE_ENTRY_CAPITAL_DELETE = "ExpenseEntryCapital_Delete",
    EXPENSE_ENTRY_CAPITAL_VIEW = "ExpenseEntryCapital_View",
    EXPENSE_ENTRY_CAPITAL_VIEW_ALL = "ExpenseEntryCapital_ViewAll",

    EXPENSE_AND_REVENUE_ADJUSTMENT_ADD = "ExpenseEntryExpenseAndRevenueAdjustment_Add",
    EXPENSE_AND_REVENUE_ADJUSTMENT_UPDATE = "ExpenseEntryExpenseAndRevenueAdjustment_Update",
    EXPENSE_AND_REVENUE_ADJUSTMENT_DELETE = "ExpenseEntryExpenseAndRevenueAdjustment_Delete",
    EXPENSE_AND_REVENUE_ADJUSTMENT_VIEW = "ExpenseEntryExpenseAndRevenueAdjustment_View",
    EXPENSE_AND_REVENUE_ADJUSTMENT_VIEW_ALL = "ExpenseEntryExpenseAndRevenueAdjustment_ViewAll",
}