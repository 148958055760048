import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Permissions } from '@celerkost/constants';
import { AppConfigService } from '@celerkost/services';
import { AuthDataService } from 'app/services/data/auth-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivateChild, CanActivate, OnInit {
  constructor(
    private authDataService: AuthDataService,
    private router: Router,
    private appConfig: AppConfigService
  ) {
  }

  ngOnInit(): void {
    this.appConfig.hideFinancialYearList.next(false);
    this.appConfig.hideFacilityList.next(false);
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.appConfig.hideFinancialYearList.next(activatedRoute.data?.headerConfig?.hideFinancialYearList ?? false);
    this.appConfig.hideFacilityList.next(activatedRoute.data?.headerConfig?.hideFacilityList ?? false);

    if (this.hasPermissions(activatedRoute.data.permissions)) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

  canActivateChild(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.hasPermissions(activatedRoute.data.permissions)) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

  private hasPermissions(allowedPermissions: Permissions[]): boolean {
    const indexArray: number[] = [];
    allowedPermissions.forEach((permission: Permissions) => {
      const index: number = this.authDataService.userPermissions.indexOf(permission);
      if (index !== -1) {
        indexArray.push(index);
      }
    });
    return indexArray.length > 0;
  }

}
