export interface Certification {
    id: number;
    isArchived: number;
    isActive: number;
    updatedBy: number;
    lastUpdatedDate: string;
    certificationImage: string;
    status: number;
    facilityId: number;
    certificationRawImage: string;
    createdBy: number;
    preparerName: string;
    firmName: string;
    mailingAddress1: string;
    mailingAddress2: string;
    city: string;
    state: string;
    zip: string;
    telephone: string;
    email: string;
    dateSubmitted: string;
    authorizedOfficer: string;
    designation: string;
    dateReviewed: string;
    financialYear: number;
}
