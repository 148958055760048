<div class="flex flex-col flex-auto">
    <div class="flex flex-col sm:flex-row items-start sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <div class="flex flex-col items-start sm:justify-between">

            <div class="text-4xl font-extrabold tracking-tight leading-none">
                <button matTooltip="Back" (click)="goBack()" *ngIf="enableBack">
                    <mat-icon svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
                {{title}}
            </div>
            <div class="font-medium text-secondary">
                <span> {{subTitle}} </span>
            </div>
        </div>
        <button *ngIf="enableDrawerToggle" class="-ml-3 sm:ml-0 mb-2 sm:mb-0 order-first sm:order-last" mat-icon-button
            (click)="toggleDrawer()">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <div class="mt-4 sm:mt-0">
            <button [disabled]="isButtonInArray(button, (loadingButtonList | async))"
                (click)="onButtonClick(button.ButtonType)" *ngFor="let button of (activeButtonList | async)"
                mat-flat-button [color]="button.Color" [matTooltip]="button.Title" class="ml-4">
                <mat-icon [svgIcon]="button.Icon"></mat-icon>
                <span class="ml-2 mr-1">{{button.Title}}</span>
                <mat-progress-spinner *ngIf="isButtonInArray(button, (loadingButtonList | async))" [diameter]="24"
                    [mode]="'indeterminate'">
                </mat-progress-spinner>
            </button>
        </div>
    </div>
</div>