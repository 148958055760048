import { Injectable } from '@angular/core';
import { CostReportYearResponse, LoginResponse, UserRole } from '@celerkost/constants';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {

  get userPermissions(): string {
    return sessionStorage.getItem('__pms_');
  }

  get userId(): number {
    return parseInt(sessionStorage.getItem('__diu_') ?? '0');
  }

  get providerId(): number {
    return parseInt(sessionStorage.getItem('__piu_') ?? '0');
  }

  get userRole(): UserRole {
    return Number(sessionStorage.getItem('__mir'));
  }

  get userModules(): string[] {
    return sessionStorage.getItem('__miu').split(',');
  }

  get userName(): string {
    return sessionStorage.getItem('__nmfl_');
  }

  get mobileNumber(): string {
    return sessionStorage.getItem('__bile_');
  }

  get emailAddress(): string {
    return sessionStorage.getItem('__imel_');
  }

  get token(): string {
    return sessionStorage.getItem('__tk_');
  }

  set token(token: string) {
    sessionStorage.setItem('__tk_', token);
  }

  set signinInData(data: LoginResponse) {
    sessionStorage.setItem('__diu_', data.id.toString());
    sessionStorage.setItem('__nmfl_', `${data.firstName} ${data.lastName}`);
    sessionStorage.setItem('__nmfn_', data.firstName);
    sessionStorage.setItem('__nmmn_', data.middleName);
    sessionStorage.setItem('__nmln_', data.lastName);
    sessionStorage.setItem('__bile_', data.mobileNumber);
    sessionStorage.setItem('__imel_', data.email);
  }

  get signinInData(): LoginResponse {
    const data: LoginResponse = {
      id: this.userId,
      firstName: sessionStorage.getItem('__nmfn_'),
      middleName: sessionStorage.getItem('__nmmn_'),
      lastName: sessionStorage.getItem('__nmln_'),
      mobileNumber: sessionStorage.getItem('__bile_'),
      email: sessionStorage.getItem('__imel_'),
    };
    return data;
  }

  set costReportYear(cRYear: CostReportYearResponse) {
    sessionStorage.setItem('__yrcr_', JSON.stringify(cRYear));
  }

  get costReportYear(): CostReportYearResponse {
    return JSON.parse(sessionStorage.getItem('__yrcr_'));
  }

  constructor() {
  }

  decodeAccessToken(token: any): void {
    const authAccessData: any = jwtDecode<any>(token.split('.')[1], { header: true });
    sessionStorage.setItem('__pms_', authAccessData?.permissions);
    sessionStorage.setItem('__piu_', authAccessData.ProviderId);
    sessionStorage.setItem('__miu', authAccessData.UserModules);
    // sessionStorage.setItem('__mir', authAccessData.Role);
    sessionStorage.setItem('__mir', authAccessData.RoleId);
  }

  hasPermission(permissionCode: string[]): boolean {
    let count: number = 0;
    permissionCode.forEach(row => {
      if (this.userPermissions.includes(row)) {
        count++;
      }
    });
    return count > 0;
  }

  hasRolePermission(permissionCode: string): boolean {
    return this.userPermissions.includes(permissionCode);
  }

  isValidUser(): boolean {
    if (this.userId) {
      return true;
    }
    return false;
  }

  isValidUserWithToken(): boolean {
    if (this.userId && this.token) {
      return true;
    }
    return false;
  }

  resetLocalStorage(): void {
    sessionStorage.clear();
  }

  signOut(): void {
    sessionStorage.clear();
  }

}
