import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, EntityQueryResult, ExpenseEntryRoutineAndSupport, QueryResult } from '@celerkost/constants';
import { Observable } from 'rxjs';
import { SearchParamsModel } from '@celerkost/models';
import { AuthDataService } from '@celerkost/services';

@Injectable({
  providedIn: 'root'
})
export class ExpenseEntryRoutineAndSupportService {

  constructor(
    private httpClient: HttpClient,
    private authDataService: AuthDataService
  ) { }

  add(expenseEntryRoutineAndSupportData): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}expenseEntryRoutineAndSupport/add`, expenseEntryRoutineAndSupportData);
  }
  update(expenseEntryRoutineAndSupportData): Observable<QueryResult> {
    return this.httpClient.put<QueryResult>(`${environment.baseApiURL}expenseEntryRoutineAndSupport/update`, expenseEntryRoutineAndSupportData);
  }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}expenseEntryRoutineAndSupport/getAll`, searchQuery);
  }

  getById(id: number): Observable<EntityQueryResult<ExpenseEntryRoutineAndSupport>> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpClient.get<EntityQueryResult<ExpenseEntryRoutineAndSupport>>(`${environment.baseApiURL}expenseEntryRoutineAndSupport/getById`, { params: params });
  }

  delete(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams().set('id', id).set('userId', this.authDataService.userId);
    return this.httpClient.delete<QueryResult>(`${environment.baseApiURL}expenseEntryRoutineAndSupport/delete`, { params: params });
  }

  getNetExpense(request): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}expenseEntryRoutineAndSupport/getNetExpense`, request);
  }

}