<fuse-loading-bar></fuse-loading-bar>

<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.nursingHome" [opened]="!isScreenSmall">
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center h-20 p-6 pb-3">
            <img [routerLink]="['/home']" class="dark:hidden w-50" src="assets/images/logo/logo-nursing-homes.png"
                alt="Logo image">
            <img [routerLink]="['/home']" class="hidden dark:flex w-50" src="assets/images/logo/logo-nursing-homes.png"
                alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<div class="flex flex-col flex-auto w-full min-w-0">

    <div
        class="relative fixed flex dark fixed flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>

        <button mat-icon-button [routerLink]="['/home']">
            <mat-icon [svgIcon]="'heroicons_outline:home'"></mat-icon>
        </button>

        <mat-form-field class="mt-4 w-1/4" *ngIf="!hideFinancialYearList">
            <mat-select [placeholder]="'Cost Reporting Year'" [value]="selectedCostReportYear?.id"
                (valueChange)="onReportingYearChanged($event)">
                <mat-option [value]="item.id" *ngFor="let item of costReportYearList; let i = index;">
                    <span class="text-mono">
                        {{item.reportingYear | date:'yyyy'}} ({{item.reportStartDate | date:'MM/dd/yyyy'}} -
                        {{item.reportEndDate | date:'MM/dd/yyyy'}})
                    </span>
                </mat-option>
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:document-report'"></mat-icon>
        </mat-form-field>

        <mat-form-field class="mt-4 w-1/5 ml-3" *ngIf="!hideFacilityList">
            <mat-select [placeholder]="'Select Facility'" (valueChange)="onFacilityChanged($event)"
                [value]="selectedFacilityId">
                <mat-option [value]="item.id" *ngFor="let item of providerFacilityList; let i = index;">
                    {{item.facilityName }}
                </mat-option>
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:library'"></mat-icon>
        </mat-form-field>

        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <notifications></notifications>
            <user></user>
        </div>
    </div>

    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <div
        class="relative flex fixed flex-0 items-center justify-between w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <div class="font-medium text-secondary">Codice CelerKost &copy; {{currentYear}}</div>
        <div>
            <span *ngIf="envTag?.active" class="rounded-lg p-1" [class]="this.envTag?.color">{{ envTag?.name }}</span>
            <span class="font-medium text-secondary ml-2">Version {{version}}</span>
        </div>
    </div>

</div>