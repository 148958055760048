import { StateCode } from "./state-code";

export interface ProviderResponse {
    id: number;
    providerName: string;
    npi: string;
    addressOne: string;
    addressTwo: string;
    zip: number;
    city: string;
    stateCode: StateCode;
    telephone: string;
    email: string;
    fax: string;
    providerImage: string;
    reportStartDate: Date;
    reportingYear: Date;
    reportEndDate: Date;
    reportingPeriodType: number;
}