import { Component, OnDestroy } from "@angular/core";
import { Subscription, BehaviorSubject, Observable } from "rxjs";

@Component({
	template: ''
})
export abstract class Subscribable implements OnDestroy {

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public loading$: Observable<boolean>;
	private subs: Subscription = new Subscription();

	set subscription(sub: Subscription) {
		this.subs.add(sub);
	}

	constructor() {
		this.loading$ = this.loading.asObservable();
	}

	unSubscribe(): void {
		this.subs.unsubscribe();
	}

	ngOnDestroy(): void {
		this.unSubscribe();
	}
}
