<!-- Generic Skeleton -->
<div *ngIf="(!isTable && !isList && !isAvatar)" [ngStyle]="myStyles" class="skelt-load loader"></div>

<!-- Generic Skeleton Table -->
<div *ngIf="isTable" class="table-dv">
    <div class="table-col-dv">
        <div [style]="headerStyles">
            <span *ngFor="let col of tHeadArray; " [ngStyle]="myStyles" class="skelt-load loader-table-header"></span>
        </div>
        <hr class="table-hr">
    </div>
</div>
<div *ngIf="isTable" class="head-hr-row">
    <hr class="table-head-hr">
</div>
<table *ngIf="isTable" class="table" [ngStyle]="myStyles">

    <div class="table-r"></div>
    <ng-container *ngFor="let row of rowsArray; let i = index;">
        <tr>
            <ng-container *ngFor="let col of colsArray; let coli = index;">
                <th [ngStyle]="myStyles" class="skelt-load loader"></th>
                <div class='table-h'></div>
                <hr class="table-hr">
            </ng-container>
        </tr>
        <div class="table-r"></div>
    </ng-container>
</table>

<!-- Generic Skeleton List -->
<ng-container *ngIf="isList">
    <mat-list-item *ngFor="let item of listRowsArray">
        <h6 [ngStyle]="myStyles" class="skelt-load loader"></h6>
    </mat-list-item>
</ng-container>

<!-- Avatar Skeleton List -->
<ng-container *ngIf="isAvatar">
    <div class="row">
        <div class="col-3">
            <img class="skelt-load loader avatar" width="50" height="50" [src]="" alt="">
        </div>
        <div class="col-8">
            <h5 class="skelt-load loader avatar-l-1"></h5>
            <h6 class="skelt-load loader avatar-l-2"></h6>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <img class="skelt-load loader avatar" width="50" height="50" [src]="" alt="">
        </div>
        <div class="col-8">
            <h5 class="skelt-load loader avatar-l-1"></h5>
            <h6 class="skelt-load loader avatar-l-2"></h6>
        </div>
    </div>
</ng-container>