export interface ExpenseEntryRoutineAndSupport {
    id: number,
    costCategory: number,
    costCategoryName: string,
    otherInfo: string,
    totalExpenses: number,
    allocationBasis: number,
    allocationBasisName: string,
    financialYear: number,
    facilityId: number,
    createdBy: number,
    createdDate: string
}

export interface ExpenseEntryNonAllowable {
    id: number,
    costCategory: number,
    costCategoryName: string,
    otherInfo: string,
    totalExpenses: number,
    financialYear: number,
    facilityId: number,
    createdBy: number,
    createdDate: string
}
export interface ExpenseEntryAdministration {
    id: number,
    costCategory: number,
    costCategoryName: string,
    otherInfo: string,
    totalExpenses: number,
    financialYear: number,
    facilityId: number,
    createdBy: number,
    createdDate: string
}

export interface ExpenseEntryNursingAndResidentCare {
    id: number,
    costCategory: number,
    otherInfo: string,
    employeeId: number,
    hourlyRate: number,
    regularHours: number,
    overtimeHours: number,
    vacationHours: number,
    holidayHours: number,
    sickHours: number,
    totalHours: number,
    totalSalary: number,
    bonus: number,
    benefits: number,
    ficaFutaSuta: number,
    totalExpenses: number,
    allocationBasis: number,
    financialYear: number,
    facilityId: number,
    createdBy: number,
    createdDate: string
}

export interface ExpenseEntryCapital {
    id: number,
    costCategory: number,
    costCategoryName: string,
    otherInfo: string,
    totalExpenses: number,
    allocationBasis: number,
    allocationBasisName: string,
    itemRented: string,
    fromWhomLeased: string,
    termOfLease: string,
    financialYear: number,
    facilityId: number,
    createdBy: number,
    createdDate: string,
}

export interface ExpenseEntryHomeOffice {
    id: number,
    costCategory: number,
    costCategoryName: string,
    otherInfo: string,
    totalExpenses: number,
    adjustments: number,
    expensesRelatedToThisFacility: number,
    expensesRelatedToOtherFacilities: number,
    allocationMethod: string,
    allocatedExpenses: number,
    allowableExpenditure: number,
    expenseToBeAllocated:number,
    financialYear: number,
    facilityId: number,
    createdBy: number,
    createdDate: string
}

export interface ExpenseEntryAdjustment {
    id: number,
    costBucket: number,
    costBucketName: string,
    costCategory: number,
    costCategoryName: string,
    adjustmentsTo: number,
    adjustmentsToName: string,
    type: number,
    typeName: string,
    otherInfo: string,
    adjustment: number,
    financialYear: number,
    facilityId: number,
    createdBy: number,
    createdDate: string
}