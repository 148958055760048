import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, QueryResult } from '@celerkost/constants';
import { SearchParamsModel } from '@celerkost/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AllocationRatesService {

  constructor(private httpClient: HttpClient) { }

  getAll(searchQuery: any): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}Allocation/GetAllAllocationBasis`, searchQuery);
  }
}
