import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QueryResult } from '@celerkost/constants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(private httpClient: HttpClient) { }

  GetProviders(): Observable<QueryResult> {
    return this.httpClient.get<QueryResult>(`${environment.baseApiURL}provider/getProviders`);
  }
  GetProviderById(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams()
      .set('id', id);
    return this.httpClient.get<QueryResult>(`${environment.baseApiURL}provider/getProviderById`, { params: params });
  }

  UpdateProviderById(formData: FormData): Observable<QueryResult> {
   
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}provider/updateProvider`, formData);
  }
}
