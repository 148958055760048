import { CostReportYearResponse, Beneficiary } from "@celerkost/constants";

export interface CashReceipt {
    id: number;
    medicaidId: number;
    beneficiaryId: number;
    beneficiary: Beneficiary
    invoiceNumber: string;
    receivedOn: string;
    invoicePaidOn: string;
    amount: number;
    adjustments: number;
    note: string;
    financialYear: number;
    displayFinancialYear: number;
    costReportYear: CostReportYearResponse;
    providerId: number;
    createdBy: number;
    updatedBy: number;
}