export interface UserResponse {
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
    dialingCode: string;
    mobileNumber: string;
    email: string;
    isActive: boolean;
    roleId:number
    providerId: number;
    moduleIds: number[]
}
