
export enum NavToolbarButtonType {
  Add,
  AddExit,
  New,
  Refresh,
  Edit,
  Assign,
  Confirm,
  Cancel,
  Back,
  ExportToPdf
}
