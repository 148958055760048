import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedListQueryResult, ExpenseEntryNursingAndResidentCare, QueryResult } from '@celerkost/constants';
import { SearchParamsModel } from '@celerkost/models';
import { AuthDataService } from 'app/services/data';
import { EntitySimpleQueryResult } from 'app/shared/constants/data-access';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpenseEntryNursingAndResidentCareService {

  constructor(private httpClient: HttpClient, private authDataService: AuthDataService) { }

  add(formData: FormData): Observable<QueryResult> {

    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}ExpenseEntryNursingAndResidentCare/Add`, formData);
  }
  update(expenseEntryNursingAndResidentCareData): Observable<QueryResult> {
    return this.httpClient.put<QueryResult>(`${environment.baseApiURL}ExpenseEntryNursingAndResidentCare/update`, expenseEntryNursingAndResidentCareData);
  }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}ExpenseEntryNursingAndResidentCare/getAll`, searchQuery);
  }

  getById(id: number): Observable<EntitySimpleQueryResult<ExpenseEntryNursingAndResidentCare>> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpClient.get<EntitySimpleQueryResult<ExpenseEntryNursingAndResidentCare>>(`${environment.baseApiURL}ExpenseEntryNursingAndResidentCare/GetDtoById`, { params: params });
  }

  delete(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams().set('id', id).set('userId', this.authDataService.userId);
    return this.httpClient.delete<QueryResult>(`${environment.baseApiURL}ExpenseEntryNursingAndResidentCare/delete`, { params: params });
  }

  getNetExpense(request): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}ExpenseEntryNursingAndResidentCare/getNetExpense`, request);
  }

}
