export interface Facility {
    id: number;
    dcMedicaidProviderId: string;
    confirmDCMedicaidProviderId: string;
    facilityName: string;
    facilityOwnership: string;
    addressOne: string;
    addressTwo: string;
    city: string;
    state: string;
    zip: string;
    ownerName: string;
    ownerAddressOne: string;
    ownerAddressTwo: string;
    telephone: string;
    fax: string;
    email: string;
    licensedOrApprovedAs: string;
    certificationLevel: string;
    organizationType: string;
    totalCertifiedBeds: number;
    squareFootageSkilledNursingFacility: number;
    squareFootageIntermediateCareFacility: number;
    squareFootageOther: number;
    createdBy: string;
    createdDate: string;
}