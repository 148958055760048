import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryResult, AdvancedListQueryResult, EntityQueryResult, Beneficiary } from '@celerkost/constants';
import { SearchParamsModel } from '@celerkost/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AuthDataService } from '../data';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {

  constructor(
    private httpClient: HttpClient,
    private authDataService: AuthDataService
  ) { }

  add(beneficiaryData): Observable<QueryResult> {
    return this.httpClient.post<QueryResult>(`${environment.baseApiURL}beneficiary/add`, beneficiaryData);
  }

  update(beneficiaryData): Observable<QueryResult> {
    return this.httpClient.put<QueryResult>(`${environment.baseApiURL}beneficiary/update`, beneficiaryData);
  }

  getAll(searchQuery: SearchParamsModel): Observable<AdvancedListQueryResult> {
    return this.httpClient.post<AdvancedListQueryResult>(`${environment.baseApiURL}beneficiary/getAll`, searchQuery);
  }

  getById(id: number): Observable<EntityQueryResult<Beneficiary>> {
    const params: HttpParams = new HttpParams().set('id', id);
    return this.httpClient.get<EntityQueryResult<Beneficiary>>(`${environment.baseApiURL}beneficiary/getById`, { params: params });
  }

  delete(id: number): Observable<QueryResult> {
    const params: HttpParams = new HttpParams().set('id', id).set('userId', this.authDataService.userId);
    return this.httpClient.delete<QueryResult>(`${environment.baseApiURL}beneficiary/delete`, { params: params });
  }
}
