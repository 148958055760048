import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HomeModule } from '@celerkost/interfaces';

@Injectable({
  providedIn: 'root'
})
export class HomeModuleService {

  constructor(private httpClient: HttpClient) { }

  getModules(): HomeModule[] {
    
    const modules: HomeModule[] = [
      {
        id: 'icfiid',
        name: 'ICFIID',
        summary: '',
        url: '/home'
      },
      {
        id: 'nursing-homes',
        name: 'Nursing Homes',
        summary: '',
        url: '/nursing-home'
      },
      {
        id: 'home-health',
        name: 'Home Health',
        summary: '',
        url: '/home'
      },
      {
        id: 'hospitals',
        name: 'Hospitals',
        summary: '',
        url: '/home'
      },
      {
        id: 'clinic',
        name: 'Clinic',
        summary: '',
        url: '/home'
      },
      {
        id: 'medicaid-waver',
        name: 'Medicaid Waver',
        summary: '',
        url: '/home'
      }
    ];

    return modules;
  }
}
