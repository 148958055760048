export interface Employee {
    id: number;
    isArchived: boolean;
    isActive: boolean;
    updatedBy: number;
    lastUpdatedDate: Date;
    createdDate: Date;
    providerId: number;
    createdBy: number;
    employeeId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    gender: number;
    dateOfBirth: Date,
    addressOne: string;
    addressTwo: string;
    city: string;
    state: string;
    zip: string;
    telephone: string;
    email: string;
    ssn: string;
    department: string;
    position: string;
    employeeType:number;
}