export const environment = {
    production: false,
    version: '1.7.57',
    baseApiURL: 'https://dev-bff.celerkost.com/api/v1/',
    encryptStorageKey: 'LSSID5BFLarLWhcgH2owmzLOdlZi0QfU',

    tag: {
        active: true,
        name: 'Development',
        color: 'bg-blue-400'
    }
};